import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types';

import styles from './PDFViewer.module.css';
import { PredictionLocation } from './Location';
import { Rotation, ZoomState } from './DocumentViewer';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Spinner } from '@components';
import { debounce, clamp } from '@utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const options = {
  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

type PDFPageProps = {
  pageIndex: number;
  width: number;
  pageClass: string;
  zoom: ZoomState;
  rotation: Rotation;
  locations?: PredictionLocation[];
};

function PDFPage({ pageIndex, width, pageClass, zoom, rotation, locations }: PDFPageProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canDraw, setCanDraw] = useState(false);
  const [patches, setPatches] = useState<Patch[] | null>(null);

  useLayoutEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d', { willReadFrequently: true });
    if (!locations || !canvas || !canDraw || !context) {
      return;
    }

    const { width, height } = canvas;
    context.save();
    for (const patch of patches ?? []) {
      context.putImageData(patch.imageData, patch.x, patch.y);
    }

    const coords: {
      x: number;
      y: number;
      r: number;
      x0: number;
      y0: number;
      w: number;
      h: number;
    }[] = [];

    for (const location of locations?.filter((location) => location.page === pageIndex.toString())) {
      const radians = (-rotation * Math.PI) / 180;
      const rotatedX = (x: number, y: number) => x * Math.cos(radians) - y * Math.sin(radians);
      const rotatedY = (x: number, y: number) => y * Math.cos(radians) + x * Math.sin(radians);
      const realX = location.x - 0.5;
      const realY = -(location.y - 0.5);
      const x = Math.floor((rotatedX(realX, realY) + 0.5) * width);
      const y = Math.floor((0.5 - rotatedY(realX, realY)) * height);
      const r = (((((location.w + location.h) / 2) * (width + height)) / 2) * 4) / 2;
      const s = Math.floor(r * 2);
      const x0 = clamp(Math.floor(x - s / 2), 0, width);
      const y0 = clamp(Math.floor(y - s / 2), 0, height);
      const w = clamp(s, 0, width - x0);
      const h = clamp(s, 0, height - y0);
      coords.push({ x, y, r, x0, y0, w, h });
    }

    const newPatches: Patch[] = [];
    for (const coord of coords) {
      const imageData = context.getImageData(coord.x0, coord.y0, coord.w, coord.h);
      newPatches.push({ x: coord.x0, y: coord.y0, imageData });
    }

    for (const coord of coords) {
      const gradient = context.createRadialGradient(coord.x, coord.y, 0, coord.x, coord.y, coord.r);
      gradient.addColorStop(0.0, '#8DFF3099');
      gradient.addColorStop(0.5, '#FFFFFF00');
      context.fillStyle = gradient;
      context.fillRect(coord.x0, coord.y0, coord.w, coord.h);
    }

    context.restore();
    setPatches(newPatches);
  }, [canDraw, locations, canvasRef, rotation, zoom, pageIndex]);

  return (
    <Page
      canvasRef={canvasRef}
      pageIndex={pageIndex}
      width={width}
      key={pageIndex}
      className={pageClass}
      scale={zoom}
      onRenderSuccess={() => setCanDraw(true)}
    />
  );
}

type PDFViewerProps = {
  doc: string;
  zoom?: ZoomState;
  rotation?: Rotation;
  setPageCount: (pages: number) => void;
  onTextSelect?: (data: { text?: string; position?: { left: number; top: number } }) => void;
  onPageChange?: (pages: number[]) => void;
  containerRef?: React.RefObject<HTMLDivElement>;
  locations?: PredictionLocation[] | undefined;
};

type Patch = {
  x: number;
  y: number;
  imageData: ImageData;
};

const PDFViewer = ({
  doc,
  zoom = 1,
  rotation = 0,
  setPageCount,
  onTextSelect,
  onPageChange,
  containerRef,
  locations,
}: PDFViewerProps): JSX.Element => {
  const [pages, setPages] = useState<null | number>(null);
  const [width, setWidth] = useState(600);
  const [internalPDFRotation, setInternalPDFRotation] = useState<number>(0);

  const onDocumentLoadSuccess: OnDocumentLoadSuccess = useCallback(
    (pdfProxy) => {
      pdfProxy.getPage(1).then((page) => setInternalPDFRotation(page.rotate));
      setPageCount(pdfProxy.numPages);
      setPages(pdfProxy.numPages);
      onPageChange?.([0]);
    },
    [onPageChange, setPageCount]
  );

  const docContainerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const container = containerRef?.current;

    function handleScroll() {
      // Minimize unnecessary work if we have no onPageChange callback function
      if (!container || !onPageChange) return;
      const elements = container?.querySelectorAll('.react-pdf__Page');
      // While we'd like to avoid querying inside the handler,
      // setting the elements' sizes right away might not have their fully rendered sizes yet.
      // @TODO: maybe there is a more effective way to set this once we know the pages have rendered?
      const elementPositions = Array.from(elements || []).map((element) => element.getBoundingClientRect());
      const containerRect = container.getBoundingClientRect();

      // Calculate the container's top and bottom positions within the viewport
      const containerTop = containerRect.top;
      const containerBottom = containerRect.bottom;

      const orderedByVisibility = elementPositions
        .map((position, index) => {
          const elementTop = position.top;
          const elementBottom = position.bottom;
          // Calculate the visible area of the element in the container
          const visibleArea = Math.min(elementBottom, containerBottom) - Math.max(elementTop, containerTop);
          return { index, visibleArea };
        })
        .filter((page) => page.visibleArea > 0)
        .sort((a, b) => b.visibleArea - a.visibleArea)
        .map((page) => page.index);

      onPageChange(orderedByVisibility);
    }

    // Avoid triggering changes constantly
    const debounceHandleScroll = debounce(handleScroll, 50);

    container?.addEventListener('scroll', debounceHandleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [onPageChange, pages, doc, zoom, rotation]);

  const docBinary = useMemo(() => {
    const docBinary = atob(doc || '');
    return { data: docBinary };
  }, [doc]);

  useEffect(() => {
    function textSelectListener() {
      if (onTextSelect && docContainerRef.current) {
        const selectedText = document.getSelection()?.toString();
        const range = selectedText ? document.getSelection()?.getRangeAt(0) : undefined;
        if (selectedText && range) {
          const commonAncestorContainer = range.commonAncestorContainer;
          if (
            commonAncestorContainer.contains(docContainerRef.current) ||
            docContainerRef.current.contains(commonAncestorContainer)
          ) {
            const containerPosition = docContainerRef.current.getBoundingClientRect();
            const selectionPosition = range.getBoundingClientRect();
            const left = selectionPosition.left - containerPosition.left;
            const top = selectionPosition.top - containerPosition.top;
            onTextSelect({ text: selectedText, position: { left, top } });
          }
        } else {
          onTextSelect({ text: undefined, position: undefined });
        }
      }
    }

    if (onTextSelect) {
      document.addEventListener('selectionchange', textSelectListener);
    }

    return () => {
      if (onTextSelect) {
        document.removeEventListener('selectionchange', textSelectListener);
      }
    };
  }, [onTextSelect]);

  useLayoutEffect(() => {
    if (docContainerRef.current) {
      const containerWidth = (docContainerRef.current.parentElement?.getBoundingClientRect().width || 500) - 90;
      setWidth(containerWidth);
    }
  }, []);

  useLayoutEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      if (docContainerRef.current) {
        const containerWidth = (docContainerRef.current.parentElement?.getBoundingClientRect().width || 500) - 90;
        setWidth(containerWidth);
      }
    }, 30);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  const pageClass = rotation ? `${styles.page} ${styles[`rotate${rotation}`]}` : styles.page;

  return (
    <Document
      file={docBinary}
      onLoadSuccess={onDocumentLoadSuccess}
      onSourceSuccess={() => setPages(null)}
      loading={<Spinner />}
      options={options}
      inputRef={(ref) => (docContainerRef.current = ref)}
      rotate={rotation !== 0 ? rotation + internalPDFRotation : undefined}
      className={styles.document}
      externalLinkTarget="_blank"
    >
      {[...new Array(pages)].map((_page, pageIndex) => {
        return (
          <PDFPage
            pageIndex={pageIndex}
            width={width}
            pageClass={pageClass}
            zoom={zoom}
            rotation={rotation}
            locations={locations}
          />
        );
      })}
    </Document>
  );
};

export default PDFViewer;
