import { Field } from '@lucidtech/las-sdk-browser';
import { styled } from '@config/stitches';
import { FIELD_TO_ICON, formatFieldTypeName } from './common';

const ReadOnlyTypeContainer = styled('div', {
  minWidth: '153px',
  minHeight: '46px',
  width: '100%',
  display: 'flex',
  border: '1px solid $gray200',
  padding: '0.5rem 0.75rem',
  fontSize: '0.9375rem',
  fontWeight: 400,
  lineHeight: 1.5,
  backgroundColor: 'white',
  alignItems: 'center',
  borderRadius: '0.375rem',
  gap: '0.4rem',
  whiteSpace: 'nowrap',
  color: '$gray500',
  '& svg': {
    width: '1.2em',
    height: '1.2em',
    color: '$gray400',
  },
});

export type ReadOnlyTypeProps = {
  type: Field['type'];
};
export function ReadOnlyType({ type }: ReadOnlyTypeProps) {
  return (
    <ReadOnlyTypeContainer>
      {FIELD_TO_ICON[type]}
      {formatFieldTypeName(type)}
    </ReadOnlyTypeContainer>
  );
}
