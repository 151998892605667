import { useSortable } from '@dnd-kit/sortable';
import { FieldConfigAction, LineItemField, TypeOption } from '../fieldConfigReducer';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from '../DragHandle';
import { Row } from '../Row';
import { Cell } from '../Cell';
import { DeleteButtonCell } from '../DeleteButtonCell';
import { useTranslation } from 'react-i18next';
import { Select } from '@components';
import { formatFieldType, formatSelectedItem, selectTypeStyles } from '../common';
import { styled } from '@config/stitches';
import { ThresholdInput } from '../../../features/flows/components/threshold-modal/threshold-input';

export const DraggableItemContainer = styled('li', {
  flexDirection: 'column',
  display: 'flex',
  borderTop: '1px solid transparent',
  borderBottom: '1px solid transparent',
  margin: '0 -30px',
  '&.active': {
    opacity: 0.5,
    borderTop: '1px dashed $gray500',
    borderBottom: '1px dashed $gray500',
  },

  '&.dragging': {
    position: 'relative',
    boxShadow: '$md',
    border: '1px solid $gray200',
    padding: '$2',
    borderRadius: '8px',
    overflow: 'hidden',
    opacity: 0.85,
  },
  '&.active:after': {
    content: ' ',
    zIndex: 10,
    display: 'block',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    opacity: 1,
    backgroundColor: 'rgb(83, 93, 198, 0.05)',
  },
});

const LINE_ITEM_VOCABULARY_OPTIONS: Array<TypeOption> = ['string', 'amount', 'date', 'numeric'];

export type DraggableLineItemFieldProps = {
  active?: boolean;
  dragging?: boolean;
  item: LineItemField;
  dispatch: React.Dispatch<FieldConfigAction>;
  itemIndex: number;
  parentIndex: number;
  isLastItem: boolean;
  showConfidences: boolean;
  canEditFields?: boolean;
};

export function DraggableLineItemField({
  dispatch,
  item,
  itemIndex,
  active,
  dragging,
  parentIndex,
  isLastItem,
  showConfidences,
  canEditFields,
}: DraggableLineItemFieldProps) {
  const { id, name, type: lineItemType } = item;
  const { t } = useTranslation();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, disabled: isLastItem });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <DraggableItemContainer
      ref={setNodeRef}
      style={style}
      className={dragging ? 'dragging' : active ? 'active' : undefined}
    >
      <div className="tw-group/main tw-flex tw-items-center">
        <DragHandle {...listeners} {...attributes} dragging={dragging} />
        <Row className="tw-flex-1 tw-px-2">
          <Cell>
            <div className="tw-flex tw-items-center">
              <input
                value={name}
                disabled={!canEditFields}
                readOnly={!canEditFields}
                className="disabled:!tw-bg-gray-50"
                onChange={(e) =>
                  dispatch({
                    type: 'nameLineItem',
                    index: itemIndex,

                    fieldIndex: parentIndex,
                    payload: e.target.value,
                  })
                }
                placeholder={
                  isLastItem
                    ? t('models-create:fields.lineItemAddPlaceHolder')
                    : t('models-create:fields.lineItemNamePlaceHolder')
                }
                aria-label="Field name"
              />
            </div>
          </Cell>
          {canEditFields ? (
            <Cell className="cell-shrink">
              {!isLastItem && (
                <Select
                  options={LINE_ITEM_VOCABULARY_OPTIONS}
                  className={selectTypeStyles()}
                  itemDisplayFormat={formatFieldType}
                  selectedItemDisplayFormat={formatSelectedItem}
                  selectedItem={lineItemType}
                  error={!lineItemType}
                  placeholder="Select type"
                  handleSelectedItemChange={(item) => {
                    if (item.selectedItem) {
                      dispatch({
                        type: 'typeLineItem',
                        index: itemIndex,
                        fieldIndex: parentIndex,
                        payload: item.selectedItem || 'string',
                      });
                    }
                  }}
                  id={`vocabulary-${id}`}
                />
              )}
            </Cell>
          ) : null}
          {showConfidences ? (
            <Cell className="confidence">
              <ThresholdInput
                value={item.confidence}
                onChange={(value) =>
                  dispatch({ type: 'confidenceLineItem', index: itemIndex, fieldIndex: parentIndex, payload: value })
                }
              />
            </Cell>
          ) : null}
          {canEditFields ? (
            <DeleteButtonCell
              disabled={isLastItem}
              onClick={() =>
                dispatch({
                  type: 'deleteLineItem',
                  index: itemIndex,
                  fieldIndex: parentIndex,
                })
              }
              aria-label={t('models-create:fields.removeFieldBtnAriaLabel')}
            />
          ) : null}
        </Row>
      </div>
    </DraggableItemContainer>
  );
}
