import { ReactNode } from 'react';
import { Field } from '@lucidtech/las-sdk-browser';
import TypeSquareIcon from '@assets/icon-type-square.svg?react';
import BankNoteIcon from '@assets/icon-bank-note-03.svg?react';
import NumbersIcon from '@assets/icon-numbers.svg?react';
import TagIcon from '@assets/icon-tag-01.svg?react';
import GridIcon from '@assets/icon-grid.svg?react';
import CalendarIcon from '@assets/icon-calendar.svg?react';
import { TypeOption } from './fieldConfigReducer';
import { css, styled } from '@config/stitches';
import { StitchesBadge } from '@components';

export const FIELD_TO_ICON: Record<Field['type'], ReactNode> = {
  amount: <BankNoteIcon />,
  date: <CalendarIcon />,
  digits: <NumbersIcon />,
  enum: <TagIcon />,
  lines: <GridIcon />,
  numeric: <NumbersIcon />,
  string: <TypeSquareIcon />,
};

export const formatFieldTypeName = (type: Field['type']): string =>
  (type && type[0].toUpperCase() + type.slice(1)) || '';

export const FIELD_TYPE_OPTIONS: Array<TypeOption> = ['string', 'amount', 'date', 'numeric', 'lines', 'enum'];
export const LINE_ITEM_FIELD_TYPE_OPTIONS: Array<TypeOption> = ['string', 'amount', 'date', 'numeric'];
export const FIELD_TYPE_INFO: Record<TypeOption, { name: string; description: string }> = {
  string: {
    name: 'String',
    description: 'Regular text',
  },
  amount: {
    name: 'Amount',
    description: 'Monetary amount with two decimals',
  },
  date: {
    name: 'Date',
    description: 'A date',
  },
  numeric: {
    name: 'Numeric',
    description: 'A number',
  },
  lines: {
    name: 'Line items',
    description: 'Table with line items',
  },
  enum: {
    name: 'Category / Classification',
    description:
      'Properties that are typically not written out on the document and must be inferred by the model such as "type of document" and "country of origin"',
  },
};

export const selectTypeStyles = css({
  width: '100%',
  height: '100%',
  minWidth: '20ch',
  '& ul li[data-selected="true"]:before': {
    backgroundColor: '$primary700',
    boxShadow: '0 0 0 4px inset white, 0 0 0 1px $colors$primary700',
  },

  '& ul li[aria-disabled="true"]': {
    backgroundColor: 'transparent',
    '&:before': {
      boxShadow: '0 0 0 1px #EAECF0',
      backgroundColor: '#EAECF0',
    },
  },

  '& ul li[aria-selected="true"]': {
    backgroundColor: '#f5f7ff',
  },

  '& ul li[aria-selected="true"]:not([data-selected="true"]):before': {
    boxShadow: '0 0 0 1px $colors$primary700',
  },

  '& ul': {
    width: '330px',
    transform: 'translate(-3ch, -50%)',
    zIndex: 9999,
  },

  '& ul li': {
    position: 'relative',
    padding: '15px 15px 15px 35px',
    borderTop: 'none !important',

    '&:before': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: '10px',
      top: '16px',
      content: ' ',
      width: '14px',
      height: '14px',
      borderRadius: '50%',
      boxShadow: '0 0 0 1px #D0D5DD',
    },

    '& h2': {
      fontSize: '0.85rem',
      marginBottom: '3px',
    },

    '& p': {
      fontSize: '0.8rem',
      color: '$gray500',
    },
  },

  '& button': {
    width: '100%',
    height: '100%',
    padding: '0.5rem 0.75rem',
  },
});

const TypeContainer = styled('div', {
  display: 'flex',
  gap: '$2',
  alignItems: 'center',
  '& svg': {
    color: '$gray500',
  },
});

export function formatFieldType(item?: TypeOption) {
  if (item) {
    return (
      <>
        <h2>{FIELD_TYPE_INFO[item].name}</h2>
        <p>{FIELD_TYPE_INFO[item].description}</p>
      </>
    );
  } else {
    return 'Unknown';
  }
}

export function formatSelectedItem(item?: TypeOption) {
  if (item) {
    return (
      <TypeContainer>
        {FIELD_TO_ICON[item]}
        {formatFieldTypeName(item)}
      </TypeContainer>
    );
  } else {
    return 'Unknown';
  }
}

const DisabledContainer = styled('div', {
  display: 'flex',
  gap: '2em',
  justifyContent: 'space-between',
});

export function formatDisabledFieldType(item?: TypeOption) {
  if (item) {
    return (
      <DisabledContainer>
        <div>
          <h2>{FIELD_TYPE_INFO[item].name}</h2>
          <p>{FIELD_TYPE_INFO[item].description}</p>
        </div>
        {item === 'lines' ? (
          <StitchesBadge css={{ alignSelf: 'flex-start', fontSize: '0.9em', whiteSpace: 'nowrap' }} variant="success">
            Beta
          </StitchesBadge>
        ) : (
          <StitchesBadge css={{ alignSelf: 'flex-start', fontSize: '0.9em', whiteSpace: 'nowrap' }} variant="info">
            Coming soon
          </StitchesBadge>
        )}
      </DisabledContainer>
    );
  } else {
    return 'Unknown';
  }
}

export const ENUM_OPTION_COLORS = [
  '#B692F6',
  '#717BBC',
  '#86CB3C',
  '#FF692E',
  '#FAC515',
  '#85E13A',
  '#22CCEE',
  '#8098F9',
  '#FD6F8E',
  '#3CCB7F',
  '#36BFFA',
  '#A48AFB',
  '#F38744',
  '#2ED3B7',
  '#53B1FD',
  '#E478FA',
  '#528BFF',
  '#F670C7',
];
