import type { FieldConfig as LasFieldConfig } from '@lucidtech/las-sdk-browser';
import { FieldConfigState } from './fieldConfigReducer';
import { ConfidenceThresholds } from '../../features/flows/stores';

export const DEFAULT_CONFIDENCE_THRESHOLD = 90;

export function normalizeFieldConfig(fields: FieldConfigState) {
  const fieldConfig: LasFieldConfig = {};
  for (const field of fields) {
    const { name: fieldName, description, type, fields: lineItems, enum: enumOptions } = field;
    if (!type) continue;
    if (fieldName) {
      // make sure enum variants are unique
      const uniqueEnumOptions = enumOptions ? [...new Set(enumOptions)] : undefined;
      fieldConfig[fieldName] = { description, type, enum: uniqueEnumOptions };
      if (type === 'lines' && lineItems && lineItems.length > 0) {
        fieldConfig[fieldName].fields = {};
        for (const lineItem of lineItems) {
          const { name: lineItemName, description: lineItemDescription, type: lineItemType } = lineItem;
          if (!lineItemType) continue;
          if (lineItemName) {
            fieldConfig[fieldName].fields![lineItemName] = {
              description: lineItemDescription,
              type: lineItemType,
            };
          }
        }
      }
    }
  }
  return fieldConfig;
}

export function normalizeConfidenceThresholds(fields: FieldConfigState) {
  const confidenceThresholds: ConfidenceThresholds = {};
  for (const field of fields) {
    const { name: fieldName, type, confidence, fields: lineItems } = field;
    if (!type) continue;
    if (fieldName) {
      confidenceThresholds[fieldName] = confidence ?? DEFAULT_CONFIDENCE_THRESHOLD;
      if (type === 'lines' && lineItems && lineItems.length > 0) {
        confidenceThresholds[fieldName] = {};
        for (const lineItem of lineItems) {
          const { name: lineItemName, type: lineItemType, confidence: lineItemConfidence } = lineItem;
          if (!lineItemType) continue;
          if (lineItemName) {
            (confidenceThresholds[fieldName] as Record<string, number>)[lineItemName] =
              lineItemConfidence ?? DEFAULT_CONFIDENCE_THRESHOLD;
          }
        }
      }
    }
  }
  return confidenceThresholds;
}
