import { CSSProperties, RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from './SimpleImageViewer.module.css';
import { getRotationStyle, getZoomStyle, Rotation, ZoomState } from './DocumentViewer';
import { PredictionLocation } from '@components/DocumentViewer/Location';
import { clamp } from '@utils';

type SimpleImageViewerProps = {
  doc: string;
  zoom?: ZoomState;
  rotation?: Rotation;
  containerRef?: RefObject<HTMLDivElement>;
  locations?: PredictionLocation[];
};

type Patch = {
  x: number;
  y: number;
  imageData: ImageData;
};

const SimpleImageViewer = ({
  doc,
  zoom = 1,
  rotation = 0,
  containerRef,
  locations,
}: SimpleImageViewerProps): JSX.Element | null => {
  const zoomStyle = getZoomStyle(zoom);
  const rotationStyle = getRotationStyle(rotation);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canDraw, setCanDraw] = useState(false);
  const [patches, setPatches] = useState<Patch[] | null>(null);
  const [dimensions, setDimensions] = useState([0, 0]);
  const [rotationContainerStyles, setRotationContainerStyles] = useState({});
  const [originalWidth, originalHeight] = dimensions;

  useEffect(() => {
    setCanDraw(false);
  }, [doc]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (canvas && context && doc) {
      context.save();
      const newImg = new Image();
      newImg.src = doc;
      newImg.onload = () => {
        canvas.width = newImg.width;
        canvas.height = newImg.height;
        context.drawImage(newImg, 0, 0, newImg.width, newImg.height, 0, 0, newImg.width, newImg.height);
        context.restore();
        setPatches(null);
        setCanDraw(true);
        setDimensions([newImg.width, newImg.height]);
      };
    }
  }, [doc, canvasRef.current]);

  useLayoutEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d', { willReadFrequently: true });
    if (!locations || !canvas || !canDraw || !context) {
      return;
    }

    const { width, height } = canvas;
    context.save();
    for (const patch of patches ?? []) {
      context.putImageData(patch.imageData, patch.x, patch.y);
    }

    const coords: {
      x: number;
      y: number;
      r: number;
      x0: number;
      y0: number;
      w: number;
      h: number;
    }[] = [];

    for (const location of locations?.filter((location) => location.page === '0')) {
      const x = Math.floor(location.x * width);
      const y = Math.floor(location.y * height);
      const r = (((((location.w + location.h) / 2) * (width + height)) / 2) * 4) / 2;
      const s = Math.floor(r * 2);
      const x0 = clamp(Math.floor(x - s / 2), 0, width);
      const y0 = clamp(Math.floor(y - s / 2), 0, height);
      const w = clamp(s, 0, width - x0);
      const h = clamp(s, 0, height - y0);
      coords.push({ x, y, r, x0, y0, w, h });
    }

    const newPatches: Patch[] = [];
    for (const coord of coords) {
      const imageData = context.getImageData(coord.x0, coord.y0, coord.w, coord.h);
      newPatches.push({ x: coord.x0, y: coord.y0, imageData });
    }

    for (const coord of coords) {
      const gradient = context.createRadialGradient(coord.x, coord.y, 0, coord.x, coord.y, coord.r);
      gradient.addColorStop(0.0, '#8DFF3099');
      gradient.addColorStop(0.5, '#FFFFFF00');
      context.fillStyle = gradient;
      context.fillRect(coord.x0, coord.y0, coord.w, coord.h);
    }

    context.restore();
    setPatches(newPatches);
  }, [canDraw, locations, canvasRef, rotation, zoom]);

  useLayoutEffect(() => {
    if (originalWidth === 0 || !canvasRef.current || !containerRef?.current) return;
    const isWider = originalWidth > originalHeight;

    const diff = Math.abs(canvasRef.current.clientWidth - canvasRef.current.clientHeight);
    const newStyles: CSSProperties = {};

    if (rotation === 90 || rotation === 270) {
      if (isWider) {
        newStyles.marginTop = (diff * zoom) / 2;
        newStyles.marginBottom = (diff * zoom) / 2;
      } else {
        newStyles.marginLeft = diff / 2;
        newStyles.marginRight = diff / 2;
      }
    }
    setRotationContainerStyles(newStyles);
  }, [canvasRef, rotation, setRotationContainerStyles, originalWidth, zoom]);

  return (
    <div className={`${zoomStyle}`}>
      <div style={rotationContainerStyles}>
        <canvas className={`${styles.image} ${rotationStyle}`} ref={canvasRef} />
      </div>
    </div>
  );
};

export default SimpleImageViewer;
