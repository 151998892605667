import { ComponentProps, MutableRefObject } from 'react';
import { Collapse } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FiTrash2 } from 'react-icons/fi';
import { Field as LasField } from '@lucidtech/las-sdk-browser';
import { CSS } from '@dnd-kit/utilities';

import { Field, FieldConfigAction, StateField, TypeOption } from './fieldConfigReducer';
import { ReadOnlyInput } from './ReadOnlyInput';
import { ReadOnlyType } from './ReadOnlyType';
import { Button, Select } from '@components';
import { styled } from '@config/stitches';
import styles from './FieldConfig.module.scss';
import EnumIcon from './icon-enum-field.svg?react';
import IconChevronRight from '@assets/icon-chevron-right.svg?react';
import {
  ENUM_OPTION_COLORS,
  FIELD_TYPE_OPTIONS,
  formatDisabledFieldType,
  formatFieldType,
  formatSelectedItem,
  selectTypeStyles,
} from './common';
import { LineItemFields } from './LineItemFields';
import { useSortable } from '@dnd-kit/sortable';
import { DragHandle } from './DragHandle';
import { Cell } from './Cell';
import { DeleteButtonCell } from './DeleteButtonCell';
import { ThresholdInput } from '../../features/flows/components/threshold-modal/threshold-input';

const EnumContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '25px',
  rowGap: '15px',
  alignItems: 'center',
});

export const InnerContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$grayblue50',
  gap: '$3',
  padding: '15px 30px',
});

export const Row = styled('div', {
  display: 'flex',
  gap: '$3',
  '& .name': {
    flexBasis: '30%',
  },
  '& .description': {
    flexBasis: '30%',
  },
  '& .type': {
    flexBasis: '30%',
    maxWidth: '23ch',
  },
  '& .button': {
    minWidth: '35px',
    maxWidth: '35px',
  },
});

export const DraggableItemContainer = styled('li', {
  backgroundColor: 'white',
  flexDirection: 'column',
  display: 'flex',
  borderTop: '1px solid transparent',
  borderBottom: '1px solid transparent',
  '&.active': {
    opacity: 0.5,
    borderTop: '1px dashed $gray500',
    borderBottom: '1px dashed $gray500',
  },
  [`&.dragging ${InnerContainer}`]: {
    margin: '0 -15px -15px -15px',
  },
  '&.dragging': {
    position: 'relative',
    boxShadow: '$md',
    border: '1px solid $gray200',
    padding: '$2',
    borderRadius: '8px',
    overflow: 'hidden',
    opacity: 0.85,
  },
  '&.active:after': {
    content: ' ',
    zIndex: 10,
    display: 'block',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    opacity: 1,
    backgroundColor: 'rgb(83, 93, 198, 0.05)',
  },
});

const EnumOption = styled('div', {
  display: 'flex',
  gap: '5px',
  width: 'calc(50% - 15px)',
  alignItems: 'center',
});

export const ButtonContainer = styled('div', {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  color: '#667085',

  '& button': {
    fontSize: '1.3rem',
  },

  '& button:hover': {
    color: '$gray900',
  },
});

export const CollapseButton = styled('button', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: 'transparent',
  color: '$grayblue500',
  alignSelf: 'flex-start',
  padding: 0,
  gap: '$3',

  '& svg': {
    transition: 'transform 200ms ease-in-out',
    width: '1em',
    height: '1em',
  },
  '&[data-collapsed="true"] svg': {
    transform: 'rotate(90deg)',
  },
});

export type FieldItemProps = {
  active?: boolean;
  dragging?: boolean;
  disabledTypes?: Array<TypeOption>;
  item: StateField;
  dispatch: React.Dispatch<FieldConfigAction>;
  itemIndex: number;
  onKeyDownHandler: (fieldIndex: number, event: React.KeyboardEvent<HTMLInputElement>) => boolean;
  tryAddingEnumOption: (fieldIndex: number) => void;
  enumInputRefs: MutableRefObject<Array<HTMLInputElement | null>>;
  showConfidences: boolean;
  canEditFields?: boolean;
} & ComponentProps<typeof DraggableItemContainer>;
export function FieldItem({
  active,
  dragging,
  disabledTypes,
  dispatch,
  enumInputRefs,
  item,
  itemIndex,
  onKeyDownHandler,
  tryAddingEnumOption,
  showConfidences,
  canEditFields,
}: FieldItemProps) {
  const { collapsed, name: fieldName, type, id, enum: enumOptions, enumUuids, fields: lineItemFields, readOnly } = item;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const { t } = useTranslation();

  const isLineItem = type === 'lines';
  const readOnlyOrPretrainedLineItem = readOnly;
  const isEnum = type === 'enum';
  const missingType = !type;

  return (
    <DraggableItemContainer
      ref={setNodeRef}
      style={style}
      className={dragging ? 'dragging' : active ? 'active' : undefined}
    >
      <div className="tw-group/main tw-flex tw-items-center">
        <DragHandle {...listeners} {...attributes} dragging={dragging} />
        <Row css={{ flex: 1 }}>
          <Cell className="name">
            {readOnlyOrPretrainedLineItem ? (
              <ReadOnlyInput name={fieldName} />
            ) : (
              <input
                value={fieldName}
                className="disabled:!tw-bg-gray-50"
                disabled={!canEditFields}
                readOnly={!canEditFields}
                onChange={(e) => dispatch({ type: 'name', index: itemIndex, payload: e.target.value })}
                placeholder={t('models-create:fields.namePlaceholder')}
                aria-label="Field name"
              />
            )}
          </Cell>
          {canEditFields ? (
            <Cell className="type">
              {readOnlyOrPretrainedLineItem ? (
                <ReadOnlyType type={type as LasField['type']} />
              ) : (
                <Select
                  className={selectTypeStyles()}
                  itemDisplayFormat={formatFieldType}
                  selectedItemDisplayFormat={formatSelectedItem}
                  options={FIELD_TYPE_OPTIONS}
                  error={missingType}
                  selectedItem={type}
                  placeholder="Select type"
                  itemDisabledConditional={(item) => Boolean(disabledTypes?.includes(item))}
                  disabledItemDisplayFormat={formatDisabledFieldType}
                  handleSelectedItemChange={(item) => {
                    if (item.selectedItem) {
                      dispatch({
                        type: 'type',
                        index: itemIndex,
                        payload: item.selectedItem as NonNullable<Field['type']>,
                      });
                    }
                  }}
                  id={`vocabulary-${id}`}
                />
              )}
            </Cell>
          ) : null}
          {!isLineItem &&
            (showConfidences ? (
              <Cell className="confidence">
                <ThresholdInput
                  value={item.confidence}
                  onChange={(value) => dispatch({ type: 'confidence', index: itemIndex, payload: value })}
                />
              </Cell>
            ) : null)}
          {canEditFields ? (
            <DeleteButtonCell
              onClick={() => dispatch({ type: 'delete', index: itemIndex })}
              aria-label={t('models-create:fields.removeFieldBtnAriaLabel')}
            />
          ) : null}
        </Row>
      </div>
      {isEnum && (
        <InnerContainer>
          <CollapseButton data-collapsed={collapsed} onClick={() => dispatch({ type: 'toggleCollapse', id })}>
            <IconChevronRight />
            Enumerations ({enumOptions?.length || 0})
          </CollapseButton>
          <Collapse in={Boolean(collapsed)} transitionDuration={200} transitionTimingFunction="ease">
            <EnumContainer>
              {enumOptions?.map((option, localOptionIndex) => (
                <EnumOption key={enumUuids[localOptionIndex]}>
                  <div
                    className={styles.enumOptionIcon}
                    style={{
                      backgroundColor: ENUM_OPTION_COLORS[localOptionIndex % ENUM_OPTION_COLORS.length],
                    }}
                  >
                    <EnumIcon />
                  </div>
                  <input
                    value={option}
                    onChange={(e) =>
                      dispatch({
                        type: 'enumChange',
                        fieldIndex: itemIndex,
                        index: localOptionIndex,
                        payload: e.target.value,
                      })
                    }
                  />
                  <div className={styles.enumDeleteButtonContainer}>
                    <Button
                      onClick={() => dispatch({ type: 'enumRemove', index: itemIndex, payload: localOptionIndex })}
                      variant="plain"
                    >
                      <FiTrash2 />
                    </Button>
                  </div>
                </EnumOption>
              ))}
              <EnumOption>
                <div className={styles.enumOptionIcon}>
                  <EnumIcon />
                </div>
                <input
                  onBlur={() => tryAddingEnumOption(itemIndex)}
                  onKeyDown={(event) => onKeyDownHandler(itemIndex, event)}
                  ref={(el) => (enumInputRefs.current[itemIndex] = el)}
                  placeholder="+ Add option"
                />
                <div className={styles.enumDeleteButtonContainer}></div>
              </EnumOption>
            </EnumContainer>
          </Collapse>
        </InnerContainer>
      )}

      {isLineItem && (
        <LineItemFields
          dispatch={dispatch}
          fields={lineItemFields}
          collapsed={collapsed}
          id={id}
          itemIndex={itemIndex}
          showConfidences={showConfidences}
          canEditFields={canEditFields}
        />
      )}
    </DraggableItemContainer>
  );
}
